export const useBrand = (brand) => {
  const portfolio = {
    essentialelements: {
      name: "Essential Elements",
      logo: "/assets/web/ee-logo.png",
      logoColor: "ee",
      hideNavMobile: true,
      description: [
        {
          desc: "Holiday Ads (Facebook, IG) ",
          roles: "ACD, copywriter, art director",
          goals:
            "reach at least <b>3x ROI on ad spend</b> by indexing on timely relevant content and edgy tone of voice",
        },
        {
          desc: "Performance Ads",
          roles:
            "ACD, copywriter, art director",
          goals:
            "<b>optimize social media performance ads</b> by testing variables in discount messaging, product use case (sports, work, etc.), user reviews, and testimonials ",
        },
      ],
      items: [
        {
          type: "title",
          desc: "Holiday Ads (Facebook, IG) ",
          roles: "ACD, copywriter, art director",
          goals:
            "Goals: reach at least <b>3x ROI on ad spend</b> by indexing on timely relevant content and edgy tone of voice",
        },
        {
          type: "image",
          alt: "Essential Elements Holiday Ad 1",
          img: "/assets/portfolio/01-ee-ho-01.png",
          text: "Holiday Ad 1",
        },
        {
          type: "image",
          alt: "Essential Elements Holiday Ad 3",
          img: "/assets/portfolio/01-ee-ho-03.jpg",
          text: "Holiday Ad 2",
        },
        {
          type: "image",
          alt: "Essential Elements Holiday Ad 4",
          img: "/assets/portfolio/01-ee-ho-04.jpg",
          text: "Holiday Ad 3",
          // wauto: true,
        },
        {
          type: "title",
          desc: "Performance Ads",
          roles: "ACD, copywriter, art director",
          goals:
            "<b>Optimize social media performance ads</b> by testing variables in discount messaging, product use case (sports, work, etc.), user reviews, and testimonials ",
        },
        {
          type: "image",
          alt: "Essential Elements Performance Ad 1",
          img: "/assets/portfolio/01-ee-prf-01.jpg",
          text: "Performance Ad 1",
        },
        {
          type: "image",
          alt: "Essential Elements Performance Ad 2",
          img: "/assets/portfolio/01-ee-prf-02.png",
          text: "Performance Ad 2",
        },
        {
          type: "image",
          alt: "Essential Elements Performance Ad 3",
          img: "/assets/portfolio/01-ee-prf-03.jpg",
          text: "Performance Ad 3",
        },
        {
          type: "image",
          alt: "Essential Elements Performance Ad 4",
          img: "/assets/portfolio/01-ee-prf-04.png",
          text: "Performance Ad 4",
        },
      ],
      brandBackUrl: "/portfolio/lowes",
      brandBackImg: "/assets/web/lowes-logo.png",
      brandBackClass: "lowes",
      brandBackName: "Lowes",
      brandNextUrl: "/portfolio/o2hydration",
      brandNextImg: "/assets/web/o2-logo.png",
      brandNextClass: "o2h",
      brandNextName: "o2 Hydration",
    },
    o2hydration: {
      name: "O2 Hydration",
      logo: "/assets/web/o2-logo.png",
      logoColor: "o2h",
      hideNavMobile: true,
      description: [
        {
          desc: "O2 → O2 Hydration rebrand",
          roles: "vp of brand, creative director, art director",
          goals:
            "lead a <b>14-month full company rebrand</b>, from product/audience R&D to new can/pack and style guide development",
        },
        {
          desc: "2023 CrossFit Games spoof booth",
          roles:
            "vp of brand, creative director, art director, Midjourney operator, copywriter",
          goals:
            "disrupt the CrossFit Games vendor pavilion with advertising for a spoof brand (“SugarAde”) taking aim at sugary sports drinks; <b>get onlookers to scan a QR code to capture lead data</b> and compare O2 to corporate competitors",
        },
        {
          desc: "2022 CrossFit Games event (pre-rebrand)",
          roles:
            "creative director, art director, event marketing and logistics coordinator",
          goals:
            "achieve <b>100% Games omnipresence</b> using “Recovery Pass” lanyards, three large-format installations, apparel, video, and print to put a can in every hand for five full days",
        },
        {
          desc: "O2 Amazon rebrand",
          roles: "vp of brand, creative director, art director",
          goals:
            "amplify the O2 rebrand on Amazon’s unique comparison-driven shopping platform by <b>analyzing competing brands and building custom PDP and A+ content</b>",
        },
        {
          desc: "Conference booth rebrand installation",
          roles: "vp of brand, creative director, art director",
          goals:
            "showcase O2’s new brand to a nationwide gym owner audience with new booth display, print collateral, and digital offer; <b>beat the previous year’s sales goals by 20%</b>",
        },
        {
          desc: "Series A crowdfunding campaign",
          roles: "creative director, art director, copywriter",
          goals:
            "create assets to <b>drive a $2M fundraise</b> from small, private investments through multiple advertising channels",
        },
      ],
      items: [
        {
          type: "title",
          desc: "O2 → O2 Hydration rebrand",
          roles: "vp of brand, creative director, art director",
          goals:
            "lead a <b>14-month full company rebrand</b>, from product/audience R&D to new can/pack and style guide development",
        },
        {
          type: "image",
          alt: "O2 Hydration 1",
          img: "/assets/portfolio/01-o2-h-old-can.png",
          text: "BEFORE",
        },
        {
          type: "image",
          alt: "O2 Hydration 2",
          img: "/assets/portfolio/02-o2-h-new-can.png",
          text: "AFTER",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/03-o2-h-styleguide.pdf",
          alt: "O2 Hydration 3",
          img: "/assets/portfolio/03-o2-h-styleguide.jpg",
        },
        {
          type: "title",
          desc: "2023 CrossFit Games spoof booth",
          roles:
            "vp of brand, creative director, art director, Midjourney operator, copywriter",
          goals:
            "disrupt the CrossFit Games vendor pavilion with advertising for a spoof brand (“SugarAde”) taking aim at sugary sports drinks; <b>get onlookers to scan a QR code to capture lead data</b> and compare O2 to corporate competitors",
        },
        {
          type: "image",
          alt: "O2 Hydration 4",
          img: "/assets/portfolio/03-o2-sugarade.gif",
          wauto: true,
        },
        {
          type: "image",
          alt: "O2 Hydration 5",
          img: "/assets/portfolio/04-o2-sugarade.jpg",
          wauto: true,
        },
        {
          type: "image",
          alt: "O2 Hydration 6",
          img: "/assets/portfolio/05-o2-sugarade.jpg",
          wauto: true,
        },
        {
          type: "image",
          alt: "O2 Hydration 7",
          img: "/assets/portfolio/06-o2-sugarade.jpg",
          wauto: true,
        },
        {
          type: "title",
          desc: "2022 CrossFit Games event (pre-rebrand)",
          roles:
            "creative director, art director, event marketing and logistics coordinator",
          goals:
            "achieve <b>100% Games omnipresence</b> using “Recovery Pass” lanyards, three large-format installations, apparel, video, and print to put a can in every hand for five full days",
        },
        {
          type: "video",
          videoId: "5195_ebsDNM",
        },
        {
          type: "title",
          desc: "O2 Amazon rebrand",
          roles: "vp of brand, creative director, art director",
          goals:
            "amplify the O2 rebrand on Amazon’s unique comparison-driven shopping platform by <b>analyzing competing brands and building custom PDP and A+ content</b>",
        },
        {
          type: "image",
          alt: "O2 Hydration 8",
          img: "/assets/portfolio/07-o2-amazon.jpg",
        },
        {
          type: "title",
          desc: "Conference booth rebrand installation",
          roles: "vp of brand, creative director, art director",
          goals:
            "showcase O2’s new brand to a nationwide gym owner audience with new booth display, print collateral, and digital offer; <b>beat the previous year’s sales goals by 20%</b>",
        },
        {
          type: "image",
          alt: "O2 Hydration 9",
          img: "/assets/portfolio/08-o2-boot.jpg",
        },
        {
          type: "title",
          desc: "Series A crowdfunding campaign",
          roles: "creative director, art director, copywriter",
          goals:
            "create assets to <b>drive a $2M fundraise</b> from small, private investments through multiple advertising channels",
        },
        {
          type: "video",
          videoId: "L4wA2rxOYhk",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/09-o2-investment-deck.pdf",
          alt: "O2 Hydration 10",
          img: "/assets/portfolio/09-o2-investment-deck.jpg",
        },
      ],
      brandBackUrl: "/portfolio/essentialelements",
      brandBackImg: "/assets/web/ee-logo.png",
      brandBackClass: "ee",
      brandBackName: "Essential Elements",
      brandNextUrl: "/portfolio/amazon",
      brandNextImg: "/assets/web/aws-logo.png",
      brandNextClass: "aws",
      brandNextName: "Amazon",
    },

    amazon: {
      name: "Amazon",
      logo: "/assets/web/aws-logo.png",
      logoColor: "aws",
      description: [
        {
          roles:
            "creative director (project pitch), art director, copy director",
          goals:
            "create an AWS Marketplace <b>e-book series</b> that a) increases marketplace buyer and seller confidence, and b) encourages prospective seller participation",
        },
      ],
      items: [
        {
          type: "document",
          donwloadURL: "/assets/portfolio/amazon-doc-1.pdf",
          alt: "Amazon Document 1",
          img: "/assets/portfolio/amazon-doc-1.jpg",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/amazon-doc-2.pdf",
          alt: "Amazon Document 2",
          img: "/assets/portfolio/amazon-doc-2.jpg",
        },
      ],
      brandBackUrl: "/portfolio/o2hydration",
      brandBackImg: "/assets/web/o2-logo.png",
      brandBackClass: "o2h",
      brandBackName: "o2 Hydration",
      brandNextUrl: "/portfolio/microsoft",
      brandNextImg: "/assets/web/microsoft-logo.png",
      brandNextClass: "ms",
      brandNextName: "Microsoft",
    },

    microsoft: {
      name: "Microsoft",
      logo: "/assets/web/microsoft-logo.png",
      logoColor: "ms",
      hideNavMobile: true,
      description: [
        {
          desc: "Azure AD",
          roles: "creative director (project pitch), art director, copywriter",
          goals:
            "create a two-minute <strong>Azure AD video spot</strong> to serve as flagship content for a B2B ad campaign",
        },
        {
          desc: "Johns Hopkins Hospital RFP summary deck",
          roles:
            "creative director (project pitch), art director, senior copywriter",
          goals:
            "develop a <strong>'door opener' deck</strong> for Johns Hopkins C-suite RFP reviewers",
        },
        {
          desc: "Visio",
          roles: "art director",
          goals:
            "create a <strong>seller playbook</strong> that facilitates better sales",
        },
        {
          desc: "Back to school",
          roles: "art director, senior copywriter",
          goals:
            "create BTS <strong>digital and print campaign assets</strong> for a nationwide product launch",
        },
      ],
      items: [
        {
          type: "title",
          desc: "Azure AD",
          roles: "creative director (project pitch), art director, copywriter",
          goals:
            "create a two-minute <strong>Azure AD video spot</strong> to serve as flagship content for a B2B ad campaign",
        },
        {
          type: "video",
          videoId: "QqExH3gfarM",
        },
        {
          type: "title",
          desc: "Johns Hopkins Hospital RFP summary deck",
          roles:
            "creative director (project pitch), art director, senior copywriter",
          goals:
            "develop a <strong>'door opener' deck</strong> for Johns Hopkins C-suite RFP reviewers",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/microsoft-johnshopkins.pptx",
          alt: "Microsoft 4",
          img: "/assets/portfolio/microsoft-johnshopkin.jpg",
          text: "CLICK TO DOWNLOAD",
        },
        {
          type: "title",
          desc: "Visio",
          roles: "art director",
          goals:
            "create a <strong>seller playbook</strong> that facilitates better sales",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/microsoft-visio.pdf",
          alt: "Microsoft 3",
          img: "/assets/portfolio/microsoft-visio.jpg",
        },
        {
          type: "title",
          desc: "Back to school",
          roles: "art director, senior copywriter",
          goals:
            "create BTS <strong>digital and print campaign assets</strong> for a nationwide product launch",
        },
        {
          type: "image",
          alt: "Microsoft 1",
          img: "/assets/portfolio/microsoft-ad-1.png",
          wauto: true,
        },
        {
          type: "image",
          alt: "Microsoft 2",
          img: "/assets/portfolio/microsoft-ad-2.png",
          wauto: true,
        },
      ],
      brandBackUrl: "/portfolio/amazon",
      brandBackImg: "/assets/web/aws-logo.png",
      brandBackClass: "aws",
      brandBackName: "Amazon",
      brandNextUrl: "/portfolio/paccar",
      brandNextImg: "/assets/web/paccar-logo.png",
      brandNextClass: "paccar",
      brandNextName: "Paccar",
      pdfContainerClassName: "portfolio-display",
      imgContainerClassName: "portfolio-grid-2",
    },

    paccar: {
      name: "PACCAR",
      logo: "/assets/web/paccar-logo.png",
      logoColor: "paccar",
      description: [
        {
          roles:
            "copy director, copywriter, gamification and training content manager",
          goals:
            "create and manage all <strong>written ad and communications content</strong> for a 6-month multinational Microsoft Teams adoption campaign",
        },
      ],
      items: [
        {
          type: "document",
          donwloadURL: "/assets/portfolio/paccar-teams-table-tents.pdf",
          alt: "PACCAR Document 1",
          img: "/assets/portfolio/paccar-teams-table-tents.jpg",
        },
        {
          type: "document",
          donwloadURL: "/assets/portfolio/paccar-teamsadoption-guide.pdf",
          alt: "PACCAR Document 2",
          img: "/assets/portfolio/paccar-teamsadoption-guide.jpg",
        },
      ],
      brandBackUrl: "/portfolio/microsoft",
      brandBackImg: "/assets/web/microsoft-logo.png",
      brandBackClass: "ms",
      brandBackName: "Microsoft",
      brandNextUrl: "/portfolio/rei",
      brandNextImg: "/assets/web/rei-logo.png",
      brandNextClass: "rei",
      brandNextName: "REI",
    },

    rei: {
      name: "REI",
      logo: "/assets/web/rei-logo.png",
      logoColor: "rei",
      description: [
        {
          roles: "contract creative",
          goals:
            "create spec <strong>digital ads</strong> that target aspirational shoppers",
        },
      ],
      items: [
        {
          type: "image",
          alt: "REI 1",
          img: "/assets/portfolio/rei-1.jpg",
        },
        {
          type: "image",
          alt: "REI 2",
          img: "/assets/portfolio/rei-2.jpg",
        },
      ],
      brandBackUrl: "/portfolio/paccar",
      brandBackImg: "/assets/web/paccar-logo.png",
      brandBackClass: "paccar",
      brandBackName: "Paccar",
      brandNextUrl: "/portfolio/tommybahamas",
      brandNextImg: "/assets/web/tommy-bahama-logo.png",
      brandNextClass: "tb",
      brandNextName: "Tommy Bahama",
    },

    sog: {
      name: "SOG",
      logo: "/assets/web/sog-logo.png",
      logoColor: "sog",
      description: [
        {
          roles: "creative lead, senior copywriter",
          goals:
            "elevate the SOG brand with <strong>web ads</strong> that highlight product differentiation, versatility and brand history",
        },
      ],
      items: [
        {
          type: "image",
          alt: "SOG 2",
          img: "/assets/portfolio/sog-2.jpg",
        },
        {
          type: "image",
          alt: "SOG 1",
          img: "/assets/portfolio/sog-1.jpg",
        },
        {
          type: "image",
          alt: "SOG 3",
          img: "/assets/portfolio/sog-3.jpg",
        },
        {
          type: "image",
          alt: "SOG 4",
          img: "/assets/portfolio/sog-vulcan-page.jpg",
        },
      ],
      brandBackUrl: "/portfolio/tommybahamas",
      brandBackImg: "/assets/web/tommy-bahama-logo.png",
      brandBackClass: "tb",
      brandBackName: "Tommy Bahama",
      brandNextUrl: "/portfolio/lowes",
      brandNextImg: "/assets/web/lowes-logo.png",
      brandNextClass: "lowes",
      brandNextName: "Lowes",
    },

    lowes: {
      name: "Lowes",
      logo: "/assets/web/lowes-logo.png",
      logoColor: "lowes",
      description: [
        {
          roles: "senior copywriter",
          goals:
            "create <strong>banner ads</strong> under the Lowe's masthead that encourage sales through subsidiary brands",
        },
      ],
      items: [
        {
          type: "image",
          alt: "Lowes 1",
          img: "/assets/portfolio/lowes-1.jpg",
        },
        {
          type: "image",
          alt: "Lowes 2",
          img: "/assets/portfolio/lowes-2.jpg",
        },
        {
          type: "image",
          alt: "Lowes 3",
          img: "/assets/portfolio/lowes-3.jpg",
          wauto: true,
        },
      ],
      brandBackUrl: "/portfolio/sog",
      brandBackImg: "/assets/web/sog-logo.png",
      brandBackClass: "sog",
      brandBackName: "SOG",
      brandNextUrl: "/portfolio/essentialelements",
      brandNextImg: "/assets/web/ee-logo.png",
      brandNextClass: "ee",
      brandNextName: "Essential Elements",
    },

    tommybahamas: {
      name: "Tommy Bahams",
      logo: "/assets/web/tommy-bahama-logo.png",
      logoColor: "tb",
      description: [
        {
          roles: "contract creative",
          goals:
            "create spec <strong>digital and print ads</strong> that serve all clothing styles",
        },
      ],
      items: [
        {
          type: "image",
          alt: "Tommy Bahama 1",
          img: "/assets/portfolio/tb-1.jpeg",
          wauto: true,
        },
        {
          type: "image",
          alt: "Tommy Bahama 2",
          img: "/assets/portfolio/tb-2.jpeg",
          wauto: true,
        },
        {
          type: "image",
          alt: "Tommy Bahama 3",
          img: "/assets/portfolio/tb-3.jpeg",
          wauto: true,
        },
      ],
      brandBackUrl: "/portfolio/rei",
      brandBackImg: "/assets/web/rei-logo.png",
      brandBackClass: "rei",
      brandBackName: "REI",
      brandNextUrl: "/portfolio/sog",
      brandNextImg: "/assets/web/sog-logo.png",
      brandNextClass: "sog",
      brandNextName: "SOG",
    },
  };

  return portfolio[brand];
};
